var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{attrs:{"title":"消息中心"}},[_c('div',{staticStyle:{"margin-bottom":"2vh"}},[_c('a-row',[_c('a-col',{attrs:{"span":20}},[_c('a-form-model',{attrs:{"layout":"inline"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.query.apply(null, arguments)}}},[_c('a-form-model-item',[_c('a-input',{attrs:{"placeholder":"标题"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('a-form-model-item',[_c('a-radio-group',{model:{value:(_vm.form.isRead),callback:function ($$v) {_vm.$set(_vm.form, "isRead", $$v)},expression:"form.isRead"}},_vm._l(([{
                                  label: '已读',
                                  value: 1,
                              }, {
                                  label: '未读',
                                  value: 0,
                              }]),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.label))])}),1)],1),_c('a-form-model-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.query}},[_vm._v("查询")]),_c('a-button',{on:{"click":_vm.reset}},[_vm._v("重置")])],1)],1)],1)],1),_c('a-col',{attrs:{"span":4}},[_c('div',{staticClass:"right"},[_c('a-button',{attrs:{"type":"primary","loading":_vm.allReadLoading,"disabled":_vm.list.length === 0},on:{"click":_vm.readAll}},[_vm._v("全部已读")])],1)])],1)],1),_c('a-table',{attrs:{"bordered":"","dataSource":_vm.list,"loading":_vm.loading,"pagination":{
          total: _vm.total,
          current: _vm.current,
          pageSize: _vm.pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
      }},on:{"change":_vm.onChange}},[_c('a-table-column',{key:"fromName",attrs:{"title":"消息来源","data-index":"fromName"}}),_c('a-table-column',{key:"title",attrs:{"title":"标题","data-index":"title"}}),_c('a-table-column',{key:"content",attrs:{"title":"内容","data-index":"content"}}),_c('a-table-column',{key:"createAt",attrs:{"title":"创建时间","data-index":"createAt","align":"center"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }