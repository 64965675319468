


<template>
  <Card title="消息中心">
    <div style="margin-bottom: 2vh;">
      <a-row>
        <a-col :span="20">
          <a-form-model layout="inline" @keyup.enter.native="query">
            <a-form-model-item>
              <a-input v-model="form.title" placeholder="标题"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-radio-group v-model="form.isRead">
                <a-radio
                  v-for="item in [{
                                    label: '已读',
                                    value: 1,
                                }, {
                                    label: '未读',
                                    value: 0,
                                }]"
                  :key="item.value"
                  :value="item.value"
                >{{ item.label }}</a-radio>
              </a-radio-group>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button type="primary" @click="query">查询</a-button>

                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>

        <a-col :span="4">
          <div class="right">
            <a-button
              @click="readAll"
              type="primary"
              :loading="allReadLoading"
              :disabled="list.length === 0"
            >全部已读</a-button>
          </div>
        </a-col>
      </a-row>
    </div>

    <a-table
      bordered
      :dataSource="list"
      @change="onChange"
      :loading="loading"
      :pagination="{
            total,
            current,
            pageSize,
            showTotal: (total) => `共 ${total} 条记录`,
        }"
    >
      <a-table-column key="fromName" title="消息来源" data-index="fromName" />
      <a-table-column key="title" title="标题" data-index="title" />
      <a-table-column key="content" title="内容" data-index="content" />
      <a-table-column key="createAt" title="创建时间" data-index="createAt" align="center" />
    </a-table>
  </Card>
</template>
  
<script>
import request from "@/api/request";

function fetchList(params) {
  return request({
    url: "/basic-service/message/list",
    params,
  });
}

function readAllRequest() {
  return request({
    url: "/basic-service/message/allRead",
    method: "post",
  });
}

export default {
  data() {
    return {
      form: {
        isRead: 0,
      },
      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      allReadLoading: false,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      const { current, pageSize } = this;

      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...this.form,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize || 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {
        isRead: 0,
      };
      this.current = 1;
      this.getList();
    },

    readAll() {
      const that = this;

      this.$confirm({
        title: `确定全部已读吗？`,
        onOk() {
          that.allReadLoading = true;
          readAllRequest().finally(() => {
            that.allReadLoading = false;
          });
        },
      });
    },
  },
};
</script>
